
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.is-invalid {
    background-color: rgba(255, 128, 0, 0.1);
}

.invalid-validation-message {
    color: red;
    font-size: 14px;
}

.top-message-outer {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999999;
}

.top-message {
    background-color: #dddddd;
    min-width: 10%;

    z-index: 999999;
    text-align: center;
    position: fixed;
    top: 0;
    font-size: 12pt;
    height: 30px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    padding: 2px;
    padding-left: 40px;
    padding-right: 40px;
}

.top-message-error {
    background-color: rgb(244, 67, 54);
    color: white;
}

.top-message-warning {
    background-color: orange;
    color: white;
}
.top-message-success {
    background-color: rgb(76, 175, 80);
    color: white;
}

.top-message-info {
    background-color: rgb(33, 150, 243);
    color: white;
}

.top-message-default {
    background-color: rgb(192, 192, 192);
    color: rgb(40, 40, 40);
}

.toolbar-divider-class{
    margin-top: 15px;
    border: none;
    height: 5px;
    background: red;
    
}
.top-title-class{
  border-bottom: 5px solid #1f5daa;
 
}
.title-divider{
    margin: 0;
    margin-top: 2px;
    border: none;
    height: 1px;
    background: royalblue;
}

.title-text{
    margin: 0;
    margin-top: 2px;
    color: #444;
}

.re-background-color{
    background-color: #FFFF00;
    /*
    background-image: linear-gradient(to top, #cd9cf2 0%, #f6f3ff 100%);
    */
    /*
    background-image: radial-gradient(circle farthest-side, #fceabb, #f8b500);
    */
    color: black;
}
.le-background-color{
    background-color: #00FFFF;
    /*background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);*/
    /*
    background-image: radial-gradient( circle farthest-side,  rgba(182,255,139,1) , rgb(7, 218, 32)  );
    */
    color: black;
}
.header-background-color{
    background-color: #FFFFF0;
    /* background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);*/
    color: black;
}

.calc-card{
  /*
  background-image: linear-gradient(to right, #00b4db, #0083b0);
  */
  background-color: #000000;
  
  padding: 5px;
  padding-right: 3px;
  /*border-radius:16px;*/
  color: black;
  
}
.job-details-card{
  background-color: #FFFFF0;
  /*
  background-image: linear-gradient(180deg, #fdfcfb 0%, #e2d1c3 100%);
  */
  padding: 5px;
  color: black;
  border-radius: 6px;
}
.app-layout-container{
  padding:0 !important;
  margin: 0 !important;
}
.calc-page-container{
  height: 'calc(100vh - 170px)'
}

.white-sub-headers{
  color: 'white' !important;
  padding: 0;
  margin: 0
}
.arrow {
    position: relative;
    width: 60px;
    background: crimson;
    height: 30px;
    line-height: 30px;
    margin-bottom: 30px;
    padding-left: 5px;
    padding-top: 3px;
    color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    
  }
    /*right arrow*/
    .arrow-right:after {
        content: "";
        position: absolute;
        right: -20px;
        top: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 20px solid crimson;
       
    
      }
  /*top arrow*/
  .arrow-top:before {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -10px;
    content: "";
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #1abc9c;
  }
  
  /*bottom arrow*/
  .arrow-bottom:after {
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    content: "";
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #1abc9c;
  }
  

  
  /*left arrow*/
  .arrow-left:before {
    content: "";
    position: absolute;
    left: -20px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #1abc9c;
  }
  /*
  .cancel-button{
    background-image: linear-gradient(to right, #f9d423 0%, #ff4e50 100%);
  }
  .save-button{
    background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
    color:white !important;
  }
  
  .calculate-button{
    background-image: linear-gradient(to top, #007adf 0%, #00ecbc 100%);
    
  }
*/
         
  .cancel-button {
    background-image: linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase !important;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .cancel-button:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
 
      /*     
  .calculate-button {
    background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA  51%, #1FA2FF  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase !important;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .calculate-button:hover {
    background-position: right center; 
    color: #fff;
    text-decoration: none;
  }
  */
  .calculate-button {
    background-image: linear-gradient(to right, #B3FFAB 0%, #12FFF7  51%, #B3FFAB  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase !important;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .calculate-button:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
 
  .calculate-button-disabled {
    background-color: gray;
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase !important;
    color: black;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }



  .print-button{
    background-image: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
  }
  .back-button{
    background-image: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
  }
  .settings-section{
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    border: solid 1 olive;
    border-radius: 10px;
    padding: 10px;
  } 

  .set-parameters-section{
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    border: solid 1 olive;
    border-radius: 10px;
    padding: 10px;
  }